@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');


/* p{
  font-family: "Playfair Display", serif;
} */

.kit-container {
  @apply max-w-[1440px] mx-auto md:px-0 px-[20px] w-full
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  background-color: #ea100f;
}

.kitchen-custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.kitchen-custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.kitchen-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ea100f;
}

.dancing-font {
  font-family: "Fira Sans Condensed", sans-serif;
}

.slick-prev:before,
.slick-next:before {
  color: #61261D !important;
}

.active-filter {
  font-weight: bold;
}

.bg-transparent {
  background-color: transparent;
  transition: background 0.3s ease;
}

.bg-image {
  background-image: url('/public/image/headerbjop.png');
  background-size: cover;
  background-position: center;
  transition: background 0.3s ease;
  min-height: 120px;
}

.menu-icon {
  display: none;
  /* Hidden by default */
}

.kit-ul {
  display: flex;
}

.header-wrapper {
  padding: 14px 20px 0 20px;
  margin: 0 20px;
}
.wrapper-inner-spacing{
  padding-top: 20px;
}


@media (max-width: 1279px) {
  .wrapper-inner-spacing{
    padding-top: 20px;
  }



  .header-wrapper {
    padding: 14px 20px 0 20px;
    margin: 0;
  }
}

@media (max-width: 1023px) {
  .menu-icon {
    display: block;
  }
  .wrapper-inner-spacing{
    padding-top:10px;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    width: 300px;
    transform: translateX(-100%);
    z-index: 100;
    padding: 20px 0 0 20px;
  }
  .kit-nav-wrapper{
    padding: 0 !important;
  }

  .kit-ul {
    display: block;
  }

  /* .kit-nav-wrap{
        transform: translateX(0);
    } */
  .search-text {
    display: none;
  }

  .header-wrapper {
    padding: 14px 10px 0 10px;
    margin: 0;
  }

  .text-color-mb {
    color: black !important;
  }

  @media (max-width:1024px){
    .text-color-mb {
      color: #81312d;
    }
  }

  .bg-image {
    min-height: 80px;
  }
  

}

@media (max-width:991px) {
  .logo {
    max-width: 100px;
  }
  .header-wrapper {
    padding: 4px 10px 0 10px;
    margin: 0;
  }
}

@media (max-width:575px) {
  nav {
    width: 100%;
  }
}

nav {
  transition: transform 0.3s ease-in-out;
}

.bg-image,
.bg-transparent {
  transition: background 0.3s ease;
}



.top-keyframe {
  animation: arrow2 2s infinite linear;
}

@keyframes arrow2 {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(20%);
  }

  100% {
    transform: translateY(0%);
  }
}

.hover-text:hover {
  animation: beat3 0.8s 1 cubic-bezier(0.4, 4, 0.3, 0.4);
  color: #f22534;
}

@keyframes beat3 {
  50% {
    -webkit-transform: scale(1.03, 0.9);
    transform: scale(1.03, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}



.slick-prev:before,
.slick-next:before {
  content: '←';
  display: none;
}

@media (max-width:1440px) {
  .kit-container {
    max-width: 1200px;
    padding: 0 20px;
  }

  .kit-nav-wrapper {
    max-width: 1200px;
    padding: 0 20px;
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}


@media (min-width:768px) {

  .slick-prev,
  .slick-next {
    display: block !important;
  }
}


@media (max-width:1024px) {
  .about-jir {
    height: 15rem;
    overflow-y: auto;
  }

  .about-fo-slide {
    height: 15rem;
    overflow-y: auto;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.animate-overlay {
  animation: appear 200ms ease-in 1;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
}



.video-play-button-ani {
  animation: arrow1 1.5s infinite linear;
}

@keyframes arrow1 {
  0% {
    transform: translate(-50%, -50%);
  }

  50% {
    transform: translate(-50%, -60%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}



@media (max-width:1600px) {

  .slider-container-2 .slick-prev {
    left: -167px;
  }

  .slider-container-2 .slick-next {
    right: -170px;
  }

  .about-container-slider-2 {
    max-width: 1301px !important;
  }
}

@media (max-width:1300px) {
  .about-container-slider-2 {
    max-width: 900px !important;
  }

  .slider-container-2 {
    top: 6% !important;
  }
}

@media (max-width:1024px) {
  .slider-container-2 .slick-prev {
    display: none !important;
  }

  .slider-container-2 .slick-next {
    display: none !important;
  }
}

.slider-container-2 .slick-prev {
  left: -167px;
}

.slider-container-2 .slick-next {
  right: -170px;
}


.card {
  width: 300px;
  height: 200px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 100px auto;
  /* Center the card */
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}




@media (max-width:1776px){
  .about-cat-img{
    width: 250px !important;
    height: auto !important;
  }
}


@media (min-width:1280px){
  .search-bar{
    right: 0;
  }
}

@media (min-width:1280px){
  .about-cat-img{
    width: 150px !important;
    height: auto !important;
  }
}

@media (max-width:1279px){
  .search-bar{
    right: 80px;
  }
}

@media (max-width:1024px){
  .search-bar{
    right: 140px;
  }
}


@media (max-width:575px){
  .search-bar{
    right: 85px;
    top: 40px;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}


.search-close-bar-show{
  display: none;
}
@media (max-width: 575px) {
  .search-form{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgba(26, 24, 24, 0.5);
    backdrop-filter: blur(2px);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all .2s;
  }
  .form-inner{
    background-color: white;
  }
  .search-close-bar{
    display: none;
  }
  .search-close-bar-show{
    display: block;
  }

}


@media (max-width:767px){
  iframe#widget2 {
    height:400px !important;
  }
}

iframe#widget2 {
  max-width: 100% !important;
}


.modal{
  backdrop-filter: blur(5px);
}
iframe#widget2 {
  border-radius: 10px !important;
}

.prodog-banner{
  display: none;
}
@media (max-width:639px){
  .prodog-banner{
    display: block;
  }
}